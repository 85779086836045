import React, { useContext } from 'react';
import img_satellite from './img_views/satellite_map.png';
import img_politic from './img_views/politic_map.png';
import MapsContext from '../../context/maps/mapsContext';
import './styles.css';

const BtnView = () => {
  const mapContext = useContext(MapsContext);
  const { satellite, setSatellite } = mapContext;

  return (
    <button className='btnview_satellite' onClick={() => setSatellite(satellite)}>
      <img src={satellite ? img_politic : img_satellite} width={70} height={70} />
      <p>{satellite ? 'Vista Normal' : 'Vista Satelital'}</p>
    </button>
  );
};

export default BtnView;
