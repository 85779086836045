import React, { useState, useContext, useEffect } from 'react';
import MapsContext from '../../context/maps/mapsContext';
//import SubCategory from './Subcategory';

const CategorieMobile = ({ data, selector, reset, parent, setChecked }) => {
  const mapContext = useContext(MapsContext);
  const { getCategoriesToFilter, getOutCategoriesToFilter, findCategorieById, filtered } = mapContext;
  const [childrenCheck, setchildrenCheck] = useState(true);

  useEffect(() => {
    setchildrenCheck(false);
  }, [reset]);

  const handleSubmitCategory = (e) => {
    const data = e.target.value;
    setchildrenCheck(!childrenCheck);
    if (!childrenCheck) {
      setChecked(false);
      getOutCategoriesToFilter(parent);
      setchildrenCheck(true);
      getCategoriesToFilter(data);
    } else {
      getOutCategoriesToFilter(data);
      setchildrenCheck(false);
    }
  };

  return (
    <div className={selector} style={{ display: 'none' }}>
      <div className='select__categorie'>
        <input value={data.id} onChange={handleSubmitCategory} id={data.id} type='checkbox' checked={childrenCheck} />
        <label htmlFor={data.id} className='select__categorie__name'>
          {data.name}
        </label>
      </div>
      {/*cat_2 &&
        cat_2.map((categorie) =>
          categorie.parent === data.id ? (
            <SubCategory key={categorie.id} sub_categorie={categorie} childrenCheck={childrenCheck} />
          ) : null
          )*/}
    </div>
  );
};

export default CategorieMobile;
