import React, { useState, useContext } from 'react';
import MapsContext from '../../context/maps/mapsContext';
import './styles.css';

const BoxFormGetMarker = () => {
  const mapContext = useContext(MapsContext);
  const { getMarkerByName, showError } = mapContext;
  const [marker, setMarker] = useState({
    data: '',
  });

  const handleChange = (e) => {
    setMarker({
      ...marker,
      [e.target.name]: e.target.value,
    });
  };
  const { data } = marker;

  //SUBMIT
  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.trim() === '') {
      showError('Ingrese los datos correctamente');
      return;
    }
    getMarkerByName(data);
    setMarker({
      data: '',
    });
  };

  return (
    <div className='box__search'>
      <form className='box__search__container' onSubmit={handleSubmit}>
        <input
          type='text'
          id='search'
          name='data'
          className='box__search__input'
          value={data}
          onChange={handleChange}
          placeholder='Buscar...'
        />{' '}
        <button type='submit' name='search' className='box__search__btn'>
          BUSCAR
        </button>
      </form>
    </div>
  );
};

export default BoxFormGetMarker;
