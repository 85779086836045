import React, { useState, useContext, useEffect } from 'react';
import MapsContext from '../../context/maps/mapsContext';

const SubCategory = ({ sub_categorie, childrenCheck }) => {
  const mapContext = useContext(MapsContext);
  const { filterByCategory, unFilterByCategory } = mapContext;

  const [subCategorieCheck, setsubCategorieCheck] = useState(false);

  useEffect(() => {
    if (childrenCheck) {
      setsubCategorieCheck(true);
    } else {
      setsubCategorieCheck(false);
    }
  }, [childrenCheck]);

  const handleSubmit = (e) => {
    let cat = e.target.value;
    if (cat.trim() === '') {
      return;
    }
    setsubCategorieCheck(!subCategorieCheck);
    if (subCategorieCheck) {
      //filterByCategory(cat);
    } else {
     // unFilterByCategory(cat);
    }
  };
  const id = 'value' + sub_categorie.id;
  
  if (sub_categorie) {
    return (
      <div className='select__subcategorie'>
        <input value={sub_categorie.id} onChange={handleSubmit} checked={subCategorieCheck} id={id} type='checkbox' />
        <label htmlFor={id} className='select__categorie__name'>
          {sub_categorie.name}
        </label>
      </div>
    );
  }
};

export default SubCategory;
