import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from '../pages/home/HomePage';
import MapPage from '../pages/map/MapPage';
import MapSettedPage from '../pages/map_setted/MapSetted';

const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index path='/' element={<HomePage />} />
        <Route path='/energia' element={<MapPage />} />
        <Route path='/energia/mapas/:id' element={<MapSettedPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
