import React, { useContext } from 'react';
import WeatherContext from '../../context/weather/weatherContext';
import img_weather from './img_views/weather.png';
import placeholder from '../../images/placeholder.png';
import './styles.css';

const BtnWeather = () => {
  const weatherContext = useContext(WeatherContext);
  const { weather_view, setWeatherView } = weatherContext;

  return (
    <button className='btnview_weather' onClick={() => setWeatherView(!weather_view)}>
      <img src={weather_view ? placeholder : img_weather} width={70} height={70} />
      <p>{weather_view ? 'Energía' : 'Vista clima'}</p>
    </button>
  );
};

export default BtnWeather;
