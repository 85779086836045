import React, { useState, useContext, useEffect } from 'react';
import './styles.css';
import $ from 'jquery';
import ArrowUp from '../../icons/arrow-up.png';
import ArrowDown from '../../icons/arrow-down.png';
import CategorieMobile from './CategorieMobile';
import MapsContext from '../../context/maps/mapsContext';

const SelectMobile = ({ values, reset, data }) => {
  const mapContext = useContext(MapsContext);
  const { findCategorieById, getCategoriesToFilter, getOutCategoriesToFilter, filtered } = mapContext;

  const [checked, setChecked] = useState(false);
  const [arrow, setArrow] = useState(false);

  let selector = `box__categories${data.name.replace(/\s+/g, '')}`;
  selector = selector.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  useEffect(() => {
    setChecked(false);
  }, [reset]);

  const onClickShowCategories = (id) => {
    $(`.${selector}`).show(500);
    setChecked(!checked);
    setArrow(true);
    if (!checked) {
      setChecked(true);
      getCategoriesToFilter(id);
    } else {
      getOutCategoriesToFilter(id);
      setChecked(false);
    }
  };

  const onClickHideCategories = () => {
    setArrow(!arrow);
    $(`.${selector}`).slideToggle(500);
  };

  return (
    <>
      <div className='select__item'>
        <input type='checkbox' id={data.name} checked={checked} onChange={() => onClickShowCategories(data.id)} />
        <label htmlFor={data.name} className='select__item__name'>
          <span>{data.name}</span>
        </label>
        <img
          height={12}
          width={16}
          src={!arrow ? ArrowDown : ArrowUp}
          alt='ArrowDown'
          onClick={onClickHideCategories}
          className='btn__arrow'
        />
      </div>
      {values
        ? values.map((val) => {
            return (
              <CategorieMobile
                key={val.id}
                selector={selector}
                data={val}
                checked={checked}
                setChecked={setChecked}
                reset={reset}
                parent={data.id}
              />
            );
          })
        : null}
    </>
  );
};

export default SelectMobile;
