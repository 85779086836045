import React, { useContext } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import MarkerDetail from './Marker';
import PolygonDetail from './Polygon';
import DetailContext from '../../context/detail/detailContext';
import './styles.css';

const Detail = () => {
  const detailContext = useContext(DetailContext);
  const { type } = detailContext;

  return (
    <>
      {type === 0 ? <MarkerDetail /> : null}
      {type === 1 ? <PolygonDetail /> : null}
    </>
  );
};

export default Detail;
