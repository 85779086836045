import React from 'react';
import './styles.css';
import logo from '../../images/logo.png';
//import logo_tecpetrol from '../../images/logo-tecpetrol.png';

const Footer = () => {
  return (
    <div id='footer'>
      <div className='footer__logo align_l'>
        {/*
        <p className='text2'>PRESENTADO POR:</p>
        <a rel='noopener noreferrer' target='_blank' href='https://www.rionegro.com.ar/novedades-tecpetrol/'>
          <img src={logo_tecpetrol} height={35} width={60} alt='logo_rn' className='logo_rn' />
        </a>
        */}
      </div>
      <div className='footer__logo align_r'>
        <p className='text2'>UN DESARROLLO DE:</p>
        <a rel='noopener noreferrer' target='_blank' href='https://www.rionegro.com.ar'>
          <img src={logo} height={18} width={110} alt='logo_rn' className='logo_rn' />
        </a>
      </div>
    </div>
  );
};

export default Footer;
