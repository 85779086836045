import React, { useReducer } from 'react';
import detailReducer from './detailReducer';
import DetailContext from './detailContext';
import { SET_DATA_MARKER, SET_TYPE_MARKER, SET_DATA_POLYGON, SET_POSTS } from './types';

const DetailState = (props) => {
  const initialState = {
    type: null,
    polygon_data: null,
    posts: null,
    marker_data: null,
  };

  //REDUCER
  const [state, dispatch] = useReducer(detailReducer, initialState);

  //FUNCTIONS
  const setDataMarker = async (data) => {
    dispatch({
      type: SET_DATA_MARKER,
      payload: data,
    });
  };

  const setType = async (data) => {
    dispatch({
      type: SET_TYPE_MARKER,
      payload: data,
    });
  };

  const setDataPolygon = async (data) => {
    dispatch({
      type: SET_DATA_POLYGON,
      payload: data,
    });
    //GET POST
    let posts = data.properties.posts;

    //VALIDATE IF THERE ARE POSTS IN POLYGON
    if (posts === null || posts === undefined || posts.length < 1) {
      //IF THERE ARE NOT POSTS
      posts = [''];
    }

    //CONVERT ARRAY POST TO STRING
    let encodedValue = posts.toString();

    //BACKEND ERROR VALIDATION
    if (encodedValue === '[object Object]') {
      encodedValue = '';
    }

    try {
      if (encodedValue !== '') {
        const response = await fetch(`${process.env.REACT_APP_RN_MAPS_DETAIL}${encodedValue}&per_page=3`).then((res) =>
          res.json()
        );
        dispatch({
          type: SET_POSTS,
          payload: response,
        });
      } else {
        dispatch({
          type: SET_POSTS,
          payload: null,
        });
      }
    } catch (error) {
      dispatch({
        type: SET_POSTS,
        payload: null,
      });
    }
  };

  return (
    <DetailContext.Provider
      value={{
        type: state.type,
        marker_data: state.marker_data,
        polygon_data: state.polygon_data,
        posts: state.posts,
        setDataMarker,
        setType,
        setDataPolygon,
      }}>
      {props.children}
    </DetailContext.Provider>
  );
};

export default DetailState;
