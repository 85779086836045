import React, { useContext, useRef, useState, useEffect } from 'react';
import Polygon from './Polygon';
import Point from './Point';
import Weather from './Weather';
import './styles.css';
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import MapsContext from '../../context/maps/mapsContext';
import WeatherContext from '../../context/weather/weatherContext';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import Loader from '../loader';
import BtnControlViews from '../btn_views';

const Map = () => {
  //CONTEXT
  const mapContext = useContext(MapsContext);
  const { DATA, filtered, position, satellite, setSatellite, zoom, error, error_text, loader } = mapContext;
  const weatherContext = useContext(WeatherContext);
  const { weather_cities, weather_view } = weatherContext;

  const ref = useRef(null);

  //LOCAL STATES
  const [map, setMap] = useState(null);

  if (ref.current !== null) {
    if (satellite === false || satellite === undefined) {
      ref.current.setUrl('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');
    } else {
      ref.current.setUrl('https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=KDxQvD4s8smbk2Acj22n');
    }
  }

  //CHANGE VIEW SATELLITE/CLASSIC
  const changeMap = () => {
    setSatellite(satellite);
  };

  useEffect(() => {
    if (!map) return;
    if (weather_view) {
      if (weather_cities.length > 0) {
        var feature = L.geoJson(weather_cities);
        map.fitBounds(feature.getBounds().pad(0.15));
      } else {
        return null;
      }
    } else {
      if (filtered.length > 0) {
        feature = L.geoJson(filtered);
        map.fitBounds(feature.getBounds().pad(0.15));
      } else if (DATA.length > 0) {
        feature = L.geoJson(DATA);
        map.fitBounds(feature.getBounds().pad(0.15));
      } else {
        return null;
      }
    }
  }, [weather_view, filtered]);

  return (
    <MapContainer
      className='map__leaflet'
      zoom={zoom}
      whenCreated={setMap}
      center={position}
      scrollWheelZoom={true}
      zoomControl={false}>
      <TileLayer ref={ref} url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
      {weather_view ? (
        <>{weather_cities?.length > 0 ? <Weather markers={weather_cities} /> : null}</>
      ) : (
        <>
          {DATA?.length > 0 ? <Polygon markers={DATA} /> : null}
          {DATA?.length > 0 ? <Point markers={DATA} /> : null}
          {filtered?.length > 0 ? <Polygon markers={filtered} /> : null}
          {filtered?.length > 0 ? <Point markers={filtered} /> : null}
        </>
      )}
      <ZoomControl position='bottomright' />
      <BtnControlViews satellite={satellite} changeMap={changeMap} />
      {error ? <p className='error_search'>{error_text}</p> : null}
      {loader ? <Loader /> : null}
    </MapContainer>
  );
};

export default Map;
