import L from 'leaflet';

export const getIconWeather = (icon) => {
  const iconBlue = new L.icon({
    iconUrl: require('../../icons/pin.png'),
    iconSize: [35, 35],
  });
  switch (icon) {
    case '01d':
      const icon01d = new L.icon({
        iconUrl: require('../../weather_icons/sol.png'),
        iconSize: [35, 35],
      });
      return icon01d;

    case '01n':
      const icon01n = new L.icon({
        iconUrl: require('../../weather_icons/luna.png'),
        iconSize: [35, 35],
      });
      return icon01n;

    case '02d':
      const icon02d = new L.icon({
        iconUrl: require('../../weather_icons/luna.png'),
        iconSize: [35, 35],
      });
      return icon02d;

    case '02n':
      const icon02n = new L.icon({
        iconUrl: require('../../weather_icons/nube_luna.png'),
        iconSize: [35, 35],
      });
      return icon02n;

    case '03d':
      const icon03d = new L.icon({
        iconUrl: require('../../weather_icons/nublado.png'),
        iconSize: [35, 35],
      });
      return icon03d;

    case '03n':
      const icon03n = new L.icon({
        iconUrl: require('../../weather_icons/nublado.png'),
        iconSize: [35, 35],
      });
      return icon03n;

    case '04d':
      const icon04d = new L.icon({
        iconUrl: require('../../weather_icons/nublado.png'),
        iconSize: [35, 35],
      });
      return icon04d;

    case '04n':
      const icon04n = new L.icon({
        iconUrl: require('../../weather_icons/nublado.png'),
        iconSize: [35, 35],
      });
      return icon04n;

    case '09d':
      const icon09d = new L.icon({
        iconUrl: require('../../weather_icons/lluvia.png'),
        iconSize: [35, 35],
      });
      return icon09d;

    case '09n':
      const icon09n = new L.icon({
        iconUrl: require('../../weather_icons/lluvia.png'),
        iconSize: [35, 35],
      });
      return icon09n;

    case '10d':
      const icon10d = new L.icon({
        iconUrl: require('../../weather_icons/lluvia.png'),
        iconSize: [35, 35],
      });
      return icon10d;

    case '10n':
      const icon10n = new L.icon({
        iconUrl: require('../../weather_icons/lluvia.png'),
        iconSize: [35, 35],
      });
      return icon10n;

    case '11d':
      const icon11d = new L.icon({
        iconUrl: require('../../weather_icons/tormentoso.png'),
        iconSize: [35, 35],
      });
      return icon11d;

    case '11n':
      const icon11n = new L.icon({
        iconUrl: require('../../weather_icons/tormentoso.png'),
        iconSize: [35, 35],
      });
      return icon11n;

    case '13d':
      const icon13d = new L.icon({
        iconUrl: require('../../weather_icons/nevado.png'),
        iconSize: [35, 35],
      });
      return icon13d;

    case '13n':
      const icon13n = new L.icon({
        iconUrl: require('../../weather_icons/nevado.png'),
        iconSize: [35, 35],
      });
      return icon13n;

    case '50d':
      const icon50d = new L.icon({
        iconUrl: require('../../weather_icons/viento.png'),
        iconSize: [35, 35],
      });
      return icon50d;

    case '50n':
      const icon50n = new L.icon({
        iconUrl: require('../../weather_icons/viento.png'),
        iconSize: [35, 35],
      });
      return icon50n;

    default:
      return iconBlue;
  }
};
