import {
  GET_MAPS,
  GET_MAPS_ERROR,
  GET_MAPS_SUCCESS,
  SET_SATELLITE,
  FILTER_BY_CATEGORY,
  FILTER_BY_CATEGORY_SUCCESS,
  FILTER_BY_CATEGORY_ERROR,
  UNFILTER_BY_CATEGORY,
  UNFILTER_BY_CATEGORY_SUCCESS,
  UNFILTER_BY_CATEGORY_ERROR,
  GET_CATEGORY_TO_FILTER_MOBILE,
  GET_OUT_CATEGORY_TO_FILTER_MOBILE,
  FILTER_BY_CATEGORY_MOBILE,
  FILTER_BY_CATEGORY_MOBILE_SUCCESS,
  FILTER_BY_CATEGORY_MOBILE_ERROR,
  FILTER_BY_MARKER,
  FILTER_BY_MARKER_ERROR,
  FILTER_BY_MARKER_SUCCESS,
  UNFILTER_ALL,
  SHOW_ERROR,
  HIDE_ERROR,
} from './types';

const mapsReducer = (state, action) => {
  switch (action.type) {
    //GET MAPS
    case GET_MAPS:
      return {
        ...state,
        map: null,
        loader: true,
      };
    case GET_MAPS_ERROR:
      return {
        ...state,
        map: null,
        loader: false,
      };
    case GET_MAPS_SUCCESS:
      return {
        ...state,
        DATA: action.payload,
        map: action.payload.filter((data) => data.geometry.type === 'Polygon'),
        markers: action.payload.filter((data) => data.geometry.type === 'Point'),
        getMap: false,
        filtered: [],
        loader: false,
        categoriesToFilter: [],
      };
    //SAT VIEW
    case SET_SATELLITE:
      return {
        ...state,
        satellite: action.payload,
      };

    //FILTER BY CATEGORIE
    case FILTER_BY_CATEGORY:
      return {
        ...state,
        loader: true,
      };
    case FILTER_BY_CATEGORY_SUCCESS:
      let filter = state.filtered.concat(action.payload);
      let hash = {};
      filter = filter.filter((o) => (hash[o.id] ? false : (hash[o.id] = true)));
      return {
        ...state,
        DATA: [],
        filtered: filter,
        loader: false,
      };
    case FILTER_BY_CATEGORY_ERROR:
      return {
        ...state,
        loader: false,
      };

    //FILTER BY CATEGORIE MOBILE
    case FILTER_BY_CATEGORY_MOBILE:
      return {
        ...state,
        loader: true,
      };
    case FILTER_BY_CATEGORY_MOBILE_SUCCESS:
      //let filtered = state.filtered.concat(action.payload);
      //let hashed = {};
      //filtered = filtered.filter((o) => (hashed[o.id] ? false : (hashed[o.id] = true)));
      return {
        ...state,
        DATA: [],
        filtered: action.payload,
        loader: false,
        categoriesToFilter: [],
      };
    case FILTER_BY_CATEGORY_MOBILE_ERROR:
      return {
        ...state,
        loader: false,
      };

    case UNFILTER_BY_CATEGORY:
      return {
        ...state,
        loader: true,
      };

    case UNFILTER_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        filtered: state.filtered.filter(
          (dato) => !dato.properties.categories?.includes(String(action.payload) || Number(action.payload))
        ),
        loader: false,
      };

    case UNFILTER_BY_CATEGORY_ERROR:
      return {
        ...state,
        loader: false,
      };

    //FILTER BY MARKER
    case FILTER_BY_MARKER:
      return {
        ...state,
        loader: true,
      };

    case FILTER_BY_MARKER_SUCCESS:
      return {
        ...state,
        DATA: [],
        filtered: action.payload,
        loader: false,
      };

    case FILTER_BY_MARKER_ERROR:
      return {
        ...state,
        loader: false,
      };

    //FILTER MOBILE
    case GET_CATEGORY_TO_FILTER_MOBILE:
      return {
        ...state,
        categoriesToFilter: [...state.categoriesToFilter, action.payload],
      };
    case GET_OUT_CATEGORY_TO_FILTER_MOBILE:
      return {
        ...state,
        categoriesToFilter: state.categoriesToFilter.filter((data) => data !== action.payload),
      };

    //UNFILTER ALL
    case UNFILTER_ALL:
      return {
        ...state,
      };

    //ERROR HANDLER
    case SHOW_ERROR:
      return {
        ...state,
        error_text: action.payload,
        error: true,
      };

    case HIDE_ERROR:
      return {
        ...state,
        error: false,
        error_text: null,
      };

    default:
      return state;
  }
};

export default mapsReducer;
