import { SET_DATA_MARKER, SET_TYPE_MARKER, SET_DATA_POLYGON, SET_POSTS } from './types';
const detailReducer = (state, action) => {
  switch (action.type) {
   case SET_DATA_MARKER:
      return {
        ...state,
        marker_data: action.payload,
      };
    case SET_TYPE_MARKER:
      return {
        ...state,
        type: action.payload,
      };
    case SET_DATA_POLYGON:
      return {
        ...state,
        polygon_data: action.payload,
      };
    case SET_POSTS:
      return {
        ...state,
        posts: action.payload,
      };
    default:
      return state;
  }
};

export default detailReducer;
