import React from 'react';
import BtnView from './btn_view';
import BtnWeather from './btn_weather';
import './styles.css';

const BtnControlViews = () => {
  return (
    <div className='control_views'>
      <BtnView />
      <BtnWeather />
    </div>
  );
};

export default BtnControlViews;
