import Routing from './routing/Routing';
import MapsState from './context/maps/mapsState';
import DetailState from './context/detail/detailState';
import InitialDataState from './context/initialData/initialDataState';
import WeatherState from './context/weather/weatherState';
import TagManager from 'react-gtm-module';

function App() {
  const tagManagerArgs = {
    gtmId: 'GTM-53H3HJW',
  };
  TagManager.initialize(tagManagerArgs);

  return (
    <InitialDataState>
      <MapsState>
        <WeatherState>
          <DetailState>
            <Routing />
          </DetailState>
        </WeatherState>
      </MapsState>
    </InitialDataState>
  );
}

export default App;
