import { GET_INITIAL_INFO, GET_INITIAL_INFO_SUCCESS, GET_INITIAL_INFO_ERROR } from './types';

const initialStateReducer = (state, action) => {
  switch (action.type) {
    case GET_INITIAL_INFO:
      return {
        ...state,
      };
    case GET_INITIAL_INFO_SUCCESS:
      return {
        ...state,
        header_link: action.payload.header_link,
        map_related_content: action.payload.map_related_content,
        zoom: action.payload.map_center.zoom,
        categories: action.payload.categories,
        cat_0: action.payload.categories.filter((data) => data.parent === 0),
        cat_1: action.payload.categories.filter((data) => data.order.toString().length <= 2 && data.parent !== 0),
        cat_2: action.payload.categories.filter((data) => data.order.toString().length === 3),
      };

    case GET_INITIAL_INFO_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default initialStateReducer;
