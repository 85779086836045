import React, { useReducer } from 'react';
import WeatherContext from './weatherContext';
import weatherReducer from './weatherReducer';
import { GET_WEATHER, GET_WEATHER_SUCCESS, GET_WEATHER_ERROR, SET_WEATHER_VIEW } from './types';

const WeatherState = (props) => {
  const initialState = {
    weather_view: false,
    weather_cities: null,
    error: false,
  };

  //REDUCER
  const [state, dispatch] = useReducer(weatherReducer, initialState);

  //FUNCTIONS
  const getWeather = async () => {
    dispatch({
      type: GET_WEATHER,
    });
    try {
      const response = await fetch('https://rionegro.com.ar/wp-json/rn/weather').then((res) => res.json());
      dispatch({
        type: GET_WEATHER_SUCCESS,
        payload: response.features,
      });
    } catch (error) {
      dispatch({
        type: GET_WEATHER_ERROR,
      });
    }
  };

  const setWeatherView = (state) => {
    dispatch({
      type: SET_WEATHER_VIEW,
      payload: state,
    });
  };

  return (
    <WeatherContext.Provider
      value={{
        weather_cities: state.weather_cities,
        weather_view: state.weather_view,
        getWeather,
        setWeatherView,
      }}>
      {props.children}
    </WeatherContext.Provider>
  );
};

export default WeatherState;
