import React, { useContext } from "react";
import $ from "jquery";
import DetailContext from "../../context/detail/detailContext";
import RelatedContent from "../related_content/RelatedContent";
import ArrowLeft from "../../icons/arrow-left.svg";
import placeholder from "../../images/placeholder.png";
import { Carousel } from "react-responsive-carousel";
import "./styles.css";

const PolygonDetail = () => {
  const detailContext = useContext(DetailContext);
  const { polygon_data, posts } = detailContext;

  const hideDetail = () => {
    if (window.innerWidth <= 500) {
      $(".detail_pol").animate({ height: "hide" });
    } else {
      $(".detail_pol").animate({ width: "hide" });
    }
  };

  const excludedKeys = [
    "categories",
    "description",
    "color",
    "images",
    "posts",
    "direccion",
    "telefono",
    "titular",
    "bloque",
    "operador",
    "titular",
    "norma-legal",
    "tipo-area",
    "link",
  ];

  const data_rc = Object.entries(polygon_data.properties)
    .filter(([key]) => !excludedKeys.includes(key))
    .map(([key, value]) => {
      const [video_image, link_poster] = value.split(",");
      return {
        acf_fc_layout: "",
        title: key.replace(/_/g, " "),
        video_image: video_image || "",
        poster: link_poster || "",
      };
    });

  return (
    <div className="detail_pol">
      <button className="detail_pol__btn" onClick={() => hideDetail()}>
        <img alt="arrow-left" width={15} height={15} src={ArrowLeft} />
      </button>
      {/* CATEGORIA DE CONCESION */}
      {polygon_data.title && (
        <div className="detail__pol__breadcrum">
          <p>
            CONCESION
            <strong>/ {polygon_data.title.toUpperCase()}</strong>
          </p>
        </div>
      )}
      {/* TITULO Y DESCRIPCION */}
      {polygon_data.title && (
        <div className="detail__pol__info__header">
          <h2>{polygon_data.title.toUpperCase()}</h2>
          {polygon_data.properties.description && (
            <h3>{polygon_data.properties.description}</h3>
          )}
        </div>
      )}
      <div className="detail__info">
        <div className="detail__info__main">
          {polygon_data.properties && polygon_data.properties.bloque && (
            <p>
              Bloque: <strong>{polygon_data.properties.bloque}</strong>
            </p>
          )}
          {polygon_data.properties && polygon_data.properties["tipo-area"] && (
            <p>
              Tipo de área:{" "}
              <strong>{polygon_data.properties["tipo-area"]}</strong>
            </p>
          )}
          {polygon_data.properties && polygon_data.properties.titular && (
            <p>
              Titular: <strong>{polygon_data.properties.titular}</strong>
            </p>
          )}
          {polygon_data.properties && polygon_data.properties.operador && (
            <p>
              Operador: <strong>{polygon_data.properties.operador}</strong>
            </p>
          )}
          {polygon_data.properties &&
            polygon_data.properties["norma-legal"] && (
              <p>
                Norma legal:{" "}
                <strong>{polygon_data.properties["norma-legal"]}</strong>
              </p>
            )}
          {polygon_data.properties && polygon_data.properties.telefono && (
            <p>
              Teléfono: <strong>{polygon_data.properties.telefono}</strong>{" "}
            </p>
          )}
        </div>
      </div>
      {/* CONTENIDO RELACIONADO */}
      {data_rc.length > 0 && <RelatedContent data={data_rc} />}
      {/* CARROUSEL NOTAS RELACIONADAS A POLIGONO */}
      {posts ? (
        <div className="detail_pol__patrocinado">
          <h2>NOTAS RELACIONADAS</h2>
          <Carousel showThumbs={false} showStatus={false} showArrows={false}>
            {posts.map((post, key) => (
              <div className="detail_pol__patrocinado__thumb" key={key}>
                <a
                  className="detail_pol__patrocinado__thumb__img"
                  target="_blank"
                  href={post.link}
                  rel="noreferrer"
                >
                  <img
                    alt={post.title.rendered}
                    width={90}
                    height={65}
                    src={
                      post.images["news--56-00-small"] !== false
                        ? post.images["news--56-00-small"]
                        : placeholder
                    }
                  />
                </a>
                <a
                  className="detail_pol__patrocinado__thumb__link"
                  target="_blank"
                  href={post.link}
                  rel="noreferrer"
                >
                  {post.title.rendered}
                </a>
              </div>
            ))}
          </Carousel>
          <hr />
        </div>
      ) : null}
    </div>
  );
};

export default PolygonDetail;
