import React, { useState, useContext } from 'react';
import MapsContext from '../../context/maps/mapsContext';
import InitialDataContext from '../../context/initialData/initialDataContext';
import SubCategory from './Subcategory';

const Category = ({ data, selector, setChecked }) => {
  const mapContext = useContext(MapsContext);
  const { filterByCategory, unFilterByCategory, findCategorie } = mapContext;

  const initialDataContext = useContext(InitialDataContext);
  const { cat_2 } = initialDataContext;
  const [childrenCheck, setchildrenCheck] = useState(true);

  const handleSubmit = (e) => {
    const data = e.target.value;
    const cat = findCategorie(data);
    setchildrenCheck(!cat);
    setChecked(false);
    if (!cat) {
      filterByCategory(data);
      setchildrenCheck(true);
    } else {
      unFilterByCategory(data);
      setchildrenCheck(false);
    }
  };

  return (
    <div className={selector} style={{ display: 'none' }}>
      <div className='select__categorie'>
        <input value={data.id} onChange={handleSubmit} id={data.id} type='checkbox' checked={findCategorie(data.id)} />
        <label htmlFor={data.id} className='select__categorie__name'>
          {data.name}
        </label>
      </div>
      {/*cat_2 &&
        cat_2.map((categorie) =>
          categorie.parent === data.id ? (
            <SubCategory key={categorie.id} sub_categorie={categorie} childrenCheck={childrenCheck} />
          ) : null
          )*/}
    </div>
  );
};

export default Category;
