export const GET_MAPS = 'GET_MAPS';
export const GET_MAPS_ERROR = 'GET_MAPS_ERROR';
export const GET_MAPS_SUCCESS = 'GET_MAPS_SUCCESS';
export const SET_SATELLITE = 'SET_SATELLITE';

export const FILTER_BY_CATEGORY = 'FILTER_BY_CATEGORY';
export const FILTER_BY_CATEGORY_SUCCESS = 'FILTER_BY_CATEGORY_SUCCESS';
export const FILTER_BY_CATEGORY_ERROR = 'FILTER_BY_CATEGORY_ERROR';

export const UNFILTER_BY_CATEGORY = 'UNFILTER_BY_CATEGORY';
export const UNFILTER_BY_CATEGORY_SUCCESS = 'UNFILTER_BY_CATEGORY_SUCCESS';
export const UNFILTER_BY_CATEGORY_ERROR = 'UNFILTER_BY_CATEGORY_ERROR';

export const GET_CATEGORY_TO_FILTER_MOBILE = 'GET_CATEGORY_TO_FILTER_MOBILE';
export const GET_OUT_CATEGORY_TO_FILTER_MOBILE = 'GET_OUT_CATEGORY_TO_FILTER_MOBILE';
export const FILTER_BY_CATEGORY_MOBILE = 'FILTER_BY_CATEGORY_MOBILE';
export const FILTER_BY_CATEGORY_MOBILE_SUCCESS = 'FILTER_BY_CATEGORY_MOBILE_SUCCESS';
export const FILTER_BY_CATEGORY_MOBILE_ERROR = 'FILTER_BY_CATEGORY_MOBILE_ERROR';

export const FILTER_BY_MARKER = 'FILTER_BY_MARKER';
export const FILTER_BY_MARKER_SUCCESS = 'FILTER_BY_MARKER_SUCCESS';
export const FILTER_BY_MARKER_ERROR = 'FILTER_BY_MARKER_ERROR';

export const UNFILTER_ALL = 'UNFILTER_ALL';

export const SHOW_ERROR = 'SHOW_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';