import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import BoxControls from '../../components/box_controls';
import BoxMobile from '../../components/box_mobile';
import Detail from '../../components/detail';
import Map from '../../components/map';
import Layout from '../../layouts/layout/Layout';
import MapsContext from '../../context/maps/mapsContext';
import InitialDataContext from '../../context/initialData/initialDataContext';
import WeatherContext from '../../context/weather/weatherContext';

const MapSettedPage = () => {
  const initialDataContext = useContext(InitialDataContext);
  const { getInitialState } = initialDataContext;
  const mapContext = useContext(MapsContext);
  const { getMarkerByName } = mapContext;
  const weatherContext = useContext(WeatherContext);
  const { getWeather, weather_view } = weatherContext;

  const params = useParams();

  useEffect(() => {
    getInitialState();
    if (params) {
      const param = params.id;
      const map = param.replace(/-/g, ' ');
      getMarkerByName(map);
      getWeather();
    }
    //eslint - disable - next - line;
  }, []);

  if (window.innerWidth <= 767) {
    return (
      <Layout>
        <Map />
        {!weather_view ? <BoxMobile /> : null}
        <Detail />
      </Layout>
    );
  } else {
    return (
      <Layout>
        <Map />
        {!weather_view ? <BoxControls /> : null}
        <Detail />
      </Layout>
    );
  }
};

export default MapSettedPage;
