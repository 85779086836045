import { useState, useRef } from "react";
import play_btn from "../../icons/play-button.svg";
import maximize from "../../icons/maximize.png";
import placeholder from "../../images/placeholder.png";
import "./styles.css";

const RelatedContent = ({ data }) => {
  if (!data) return null;
  return (
    <>
      {data?.map((field, i) => {
        if (!field.video_image?.trim()) {
          return null;
        }

        return (
          <div className="related__content" key={i}>
            <h3>{field.title.toUpperCase()}</h3>
            <Thumbnail data={field} />
          </div>
        );
      })}
    </>
  );
};

const Thumbnail = ({ data }) => {
  const isvideo = isVideo(data.video_image);

  return (
    <figure className="related__content__thumb">
      {isvideo ? (
        <VideoThumbnail src={data.video_image} poster={data.poster} />
      ) : (
        <ImageThumbnail data={data} />
      )}
    </figure>
  );
};

const VideoThumbnail = ({ src, poster }) => {
  const videoRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);


  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className="related__content__thumb__video">
        <video
          ref={videoRef}
          height="100%"
          width="100%"
          src={src}
          poster={poster || poster === "" ? poster : placeholder}
        ></video>
        <span>
          <img
            src={play_btn}
            width={40}
            height={40}
            onClick={openModal}
            alt="play-btn"
          />
        </span>
      </div>
      {modalOpen && (
        <div className="related__content__modal">
          <div className="related__content__modal-content">
            <span
              className="related__content__modal-close"
              onClick={closeModal}
            >
              &times;
            </span>
            <video
              src={src}
              controls
              poster={poster || poster === "" ? poster : placeholder}
            ></video>
          </div>
        </div>
      )}
    </>
  );
};

const ImageThumbnail = ({ data }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className="related__content__thumb__image">
        <img
          src={data.video_image}
          height="auto"
          width="100%"
          alt={data.title}
        />
        <span onClick={openModal}>
          <img src={maximize} width={30} height={30} alt="maximize" />
        </span>
      </div>
      {modalOpen && (
        <div className="related__content__modal">
          <div className="related__content__modal-content">
            <span
              className="related__content__modal-close"
              onClick={closeModal}
            >
              &times;
            </span>
            <img src={data.video_image} alt={data.title} />
          </div>
        </div>
      )}
    </>
  );
};

const isVideo = (url) => {
  return url.includes("jwplatform");
};

export default RelatedContent;
