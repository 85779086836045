import React, { useContext } from 'react';
import $ from 'jquery';
import './styles.css';
import { GeoJSON } from 'react-leaflet';
import DetailContext from '../../context/detail/detailContext';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const Polygon = ({ markers }) => {
  //CONTEXT
  const detailContext = useContext(DetailContext);
  const { setType, setDataPolygon } = detailContext;

  const onEach = (feature, layer) => {
    let popupContent = feature.title;
    layer.bindPopup(popupContent);
    layer.on('mouseover', function () {
      this.openPopup();
    });
    layer.on('mouseout', function () {
      this.closePopup();
    });
  };

  const showPoligon = (data) => {
    setDataPolygon(data);
    setType(1);
    if (window.innerWidth <= 500) {
      $('.detail_pol').animate({ height: 'show' });
    } else {
      $('.detail_pol').animate({ width: 'show' });
    }
  };

  return markers.map((data) =>
    data.geometry.type === 'Polygon' ? (
      <GeoJSON
        key={data.id}
        data={data}
        onEachFeature={onEach}
        style={{ color: data.properties.color, weight: 1, fillOpacity: 0.9 }}
        eventHandlers={{
          click: () => {
            showPoligon(data);
          },
        }}
      />
    ) : null
  );
};

export default Polygon;
