import React, { useState, useContext } from "react";
import $ from "jquery";
import "./styles.css";
import MapsContext from "../../context/maps/mapsContext";
import InitialDataContext from "../../context/initialData/initialDataContext";
import BoxFormGetMarker from "./BoxFormGetMarker";
import RelatedContent from "../related_content/RelatedContent";
import Select from "../select";
import search_menu from "../../icons/search-menu.svg";
import search_glass from "../../icons/search-glasses.svg";
import ArrowDown from "../../icons/arrow-down.png";
import ArrowUp from "../../icons/arrow-up.png";
import oil_rig from "../../icons/oil-rig.svg";
import mail from "../../images/mail.png";

const BoxControls = () => {
  const initialDataContext = useContext(InitialDataContext);
  const { categories, cat_0, map_related_content } = initialDataContext;

  const mapContext = useContext(MapsContext);
  const { getMaps } = mapContext;

  const [reset, setReset] = useState(false);
  const [arrow, changeArrow] = useState(true);
  const [arrowrc, changeArrowRc] = useState(true);

  const showItems = () => {
    $(".box__items").slideToggle(500);
    changeArrow(!arrow);
  };

  const showRc = () => {
    $(".box__related__content").slideToggle(500);
    changeArrowRc(!arrowrc);
  };

  const showSearch = () => {
    $(".box__search").slideToggle(500);
  };

  //UNFILTER
  const unFilter = () => {
    setReset(!reset);
    getMaps();
  };

  return (
    <div className="box__controls__container">
      <div className="box__controls__item">
        <img src={oil_rig} height={20} width={20} alt="oil-rig" />
        <p>Contenido de la industria:</p>
        <div className="box__btn__arrow" onClick={() => showRc()}>
          <img
            src={arrowrc ? ArrowDown : ArrowUp}
            height={12}
            width={16}
            alt="ArrowUp"
          />
        </div>
      </div>
      <div className="box__related__content">
        <RelatedContent data={map_related_content} />
      </div>
      <div className="box__controls__item">
        <img src={search_menu} height={15} width={15} alt="search_menu" />
        <p>Seleccionar filtros:</p>
        <div className="box__btn__arrow" onClick={() => showItems()}>
          <img
            src={arrow ? ArrowDown : ArrowUp}
            height={12}
            width={16}
            alt="ArrowUp"
          />
        </div>
        <div className="box__btn__arrow">
          <img
            src={search_glass}
            onClick={() => showSearch()}
            height={16}
            width={16}
            alt="search_glass"
          />
        </div>
      </div>
      <BoxFormGetMarker />
      <div className="box__items">
        {cat_0 &&
          cat_0.map((data) => (
            <Select
              data={data}
              key={data.id}
              name={data.name}
              values={
                categories &&
                categories.filter((subdata) => subdata.parent === data.id)
              }
              reset={reset}
            />
          ))}
        <div className="remove__filtro" onClick={unFilter}>
          <p className="remove__filtro__text">Remover filtros</p>
        </div>
      </div>
      <div className="box__controls__item">
        <a href={`mailto:publicidadonline@rionegro.com.ar`}>
          <img
            src={mail}
            width={20}
            height={16}
            alt="footer_logo_mail"
            className="mail__img"
          />
          <p>CONTACTO COMERCIAL</p>
        </a>
      </div>
    </div>
  );
};

export default BoxControls;
