import React from "react";
import "./styles.css";
import energia_logo from "../../images/energia_logo.png";
//import logo_tecpetrol from "../../images/logo-tecpetrol.png";

const Home = () => {
  return (
    <div className="home">
      <div className="home__map__name">
        <h1>
          MAPA INTERACTIVO <strong>VACA MUERTA</strong>
        </h1>
      </div>
      <div className="home__map__logo">
        <img src={energia_logo} width={300} height={60} alt="energia_logo" />
        {/*<img src={logo_tecpetrol} height={80} width={150} alt="logo_rn" />*/}
      </div>
    </div>
  );
};

export default Home;
