import React, { useContext } from 'react';
import $ from 'jquery';
import './styles.css';
import { Marker, Popup } from 'react-leaflet';
import DetailContext from '../../context/detail/detailContext';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const Point = ({ markers }) => {
  //TEMPORARY ICON
  const iconBlue = new L.icon({
    iconUrl: require('../../icons/pin.png'),
    iconSize: [35, 35],
  });

  //CONTEXT
  const detailContext = useContext(DetailContext);
  const { setType, setDataMarker } = detailContext;

  //SET DATA POLIGONS && MARKERS
  const showMarker = (data) => {
    setDataMarker(data);
    setType(0);
    if (window.innerWidth <= 500) {
      $('.detail_pol').animate({ height: 'show' });
    } else {
      $('.detail_pol').animate({ width: 'show' });
    }
  };

  return markers.map((data) =>
    data.geometry.type === 'Point' ? (
      <Marker
        key={data.id}
        icon={iconBlue}
        position={[data.geometry.coordinates[1], data.geometry.coordinates[0]]}
        eventHandlers={{
          click: () => {
            showMarker(data);
          },
        }}>
        <Popup>
          <span>{data.title}</span>
        </Popup>
      </Marker>
    ) : null
  );
};

export default Point;
