import React from 'react';
import Footer from '../../components/footer';

const LayoutHome = (props) => {
  return (
    <>
      <div>{props.children}</div>
      <Footer/>
    </>
  );
};

export default LayoutHome;
