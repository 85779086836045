import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import WeatherContext from "../../context/weather/weatherContext";
import InitialDataContext from "../../context/initialData/initialDataContext";
import $ from "jquery";
import "./styles.css";
import energia from "../../images/energia_logo.png";
import menu_burguer from "../../images/menu-icon-white.svg";
import close from "../../images/close-icon-white.svg";
//import logo_tecpetrol from "../../images/logo-tecpetrol.png";

const Header = () => {
  const weatherContext = useContext(WeatherContext);
  const initialDataContext = useContext(InitialDataContext);
  const { weather_view } = weatherContext;
  const { header_link } = initialDataContext;
  const [menuIcon, setMenuIcon] = useState(true);

  const showBox = () => {
    $(".box__container__mobile").slideToggle(500);
    setMenuIcon(!menuIcon);
  };

  return (
    <div className="header">
      <div className="header__left">
        <h2>
          MAPA INTERACTIVO <strong>VACA MUERTA</strong>
        </h2>
        {!weather_view ? (
          <button onClick={() => showBox()} className="header__btn__menu">
            <img
              src={menuIcon ? menu_burguer : close}
              height={20}
              width={20}
              alt="header-btn-menu"
            />
          </button>
        ) : null}
      </div>
      <div className="header__right">
        {header_link !== null ? (
          <>
            <Link to="/">
              <img height={20} width={100} src={energia} alt="header-logo" />
            </Link>
            {/*<a target="_blank" rel="noreferrer" href={header_link.url}>
              <span>PRESENTADO POR</span>
              <img src={logo_tecpetrol} height={50} width={85} alt="logo_rn" />
            </a>*/}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Header;
