import React, { useState, useContext } from "react";
import $ from "jquery";
import "./styles.css";
import MapsContext from "../../context/maps/mapsContext";
import InitialDataContext from "../../context/initialData/initialDataContext";
import ArrowDown from "../../icons/arrow-down.png";
import ArrowUp from "../../icons/arrow-up.png";
import BoxFormGetMarker from "../box_controls/BoxFormGetMarker";
import RelatedContent from "../related_content/RelatedContent";
import SelectMobile from "../select_mobile";

const BoxMobile = () => {
  const initialDataContext = useContext(InitialDataContext);
  const { categories, cat_0, map_related_content } = initialDataContext;
  const mapContext = useContext(MapsContext);
  const { getMaps, filterByCategoryMobile, categoriesToFilter } = mapContext;

  const [arrow, changeArrow] = useState(true);
  const [arrowrc, changeArrowRc] = useState(true);
  const [reset, setReset] = useState(false);

  const showItems = () => {
    $(".box__items__mobile").slideToggle(500);
    changeArrow(!arrow);
  };

  //UNFILTER
  const unFilter = () => {
    setReset(!reset);
    getMaps();
    showItems();
  };

  const handleSubmit = () => {
    if (categoriesToFilter.length > 0) {
      filterByCategoryMobile(categoriesToFilter);
      setReset(!reset);
      showItems();
    } else {
      showItems();
    }
  };

  const showRc = () => {
    $(".box__related__content").slideToggle(500);
    changeArrowRc(!arrowrc);
  };

  return (
    <div className="box__container__mobile">
      <div className="box__container__mobile__text">
        <p>Contenido de la industria:</p>
        <div className="box__btn__arrow" onClick={() => showRc()}>
          <img
            src={arrowrc ? ArrowDown : ArrowUp}
            height={12}
            width={16}
            alt="ArrowUp"
          />
        </div>
      </div>
      <div className="box__related__content">
        <RelatedContent data={map_related_content} />
      </div>
      <BoxFormGetMarker />
      <div className="box__container__mobile__text">
        <p onClick={() => showItems()}>Seleccionar filtros:</p>
        <div className="box__btn__arrow" onClick={() => showItems()}>
          <img
            src={arrow ? ArrowDown : ArrowUp}
            height={12}
            width={16}
            alt="ArrowUp"
          />
        </div>
      </div>
      <div className="box__items__mobile">
        {cat_0 &&
          cat_0.map((data) => (
            <SelectMobile
              reset={reset}
              setReset={setReset}
              key={data.id}
              name={data.name}
              values={
                categories &&
                categories.filter((subdata) => subdata.parent === data.id)
              }
              data={data}
            />
          ))}
        <div className="remove__filtro" onClick={unFilter}>
          <p className="remove__filtro__text">Remover filtros</p>
        </div>
        <button
          type="submit"
          onClick={handleSubmit}
          name="search"
          className="box__app_filter__btn"
        >
          APLICAR FILTROS
        </button>
      </div>
      <div className="box__container__mobile__text">
        <a href={`mailto:publicidadonline@rionegro.com.ar`}>
          <p>CONTACTO COMERCIAL</p>
        </a>
      </div>
    </div>
  );
};

export default BoxMobile;
