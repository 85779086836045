import React from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import './styles.css';

const Layout = (props) => {
  return (
    <div className='layout'>
      <Header />
      <div>{props.children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
