import React, { useReducer } from "react";
import initialStateReducer from "./initialDataReducer";
import InitialDataContext from "./initialDataContext";
import {
  GET_INITIAL_INFO,
  GET_INITIAL_INFO_SUCCESS,
  GET_INITIAL_INFO_ERROR,
} from "./types";

const InitialDataState = (props) => {
  const initialState = {
    zoom: false,
    categories: [],
    cat_0: null,
    cat_1: null,
    cat_2: null,
    cat_parent: null,
    header_link: null,
    map_related_content: null,
  };

  //REDUCER
  const [state, dispatch] = useReducer(initialStateReducer, initialState);

  //FUNCTIONS
  const getInitialState = async () => {
    dispatch({
      type: GET_INITIAL_INFO,
    });
    try {
      const response = await fetch(process.env.REACT_APP_RN_MAPS_INITIAL_STATE).then((res) => res.json());
      dispatch({
        type: GET_INITIAL_INFO_SUCCESS,
        payload: response,
      });
    } catch (error) {
      console.error();
      dispatch({
        type: GET_INITIAL_INFO_ERROR,
      });
    }
  };

  const getCategorieParent = (id) => {
    if (id === null || id === undefined) {
      return null;
    }
    const parent = id.filter((data) => data !== "28908");
    if (parent.length < 1) {
      return null;
    }
    let categorie = state.categories.filter(
      (cat) => cat.id === Number(parent[0])
    );
    let cat = categorie[0]?.name;
    if (cat?.trim()) {
      return cat.toUpperCase();
    } else {
      return null;
    }
  };

  return (
    <InitialDataContext.Provider
      value={{
        getInitialState,
        zoom: state.zoom,
        categories: state.categories,
        cat_0: state.cat_0,
        cat_1: state.cat_1,
        cat_2: state.cat_2,
        header_link: state.header_link,
        map_related_content: state.map_related_content,
        getCategorieParent,
      }}
    >
      {props.children}
    </InitialDataContext.Provider>
  );
};

export default InitialDataState;
