import React from 'react';
import './styles.css';
import { Marker, Tooltip } from 'react-leaflet';
import { getIconWeather } from './icons';
import 'leaflet/dist/leaflet.css';

const Weather = ({ markers }) => {
  return markers.map((data) => (
    <Marker
      key={data.id}
      icon={getIconWeather(data.properties.icon)}
      position={[data.geometry.coordinates[1], data.geometry.coordinates[0]]}>
      <Tooltip direction='bottom' offset={[20, 15]} opacity={1} permanent interactive={true}>
        <a className='weather_city_link' href={data.properties.link}>
          {data.title} <span className='temp'>{Math.round(data.properties.temp)}°C</span>
        </a>
      </Tooltip>
    </Marker>
  ));
};

export default Weather;
