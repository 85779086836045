import { GET_WEATHER, GET_WEATHER_ERROR, GET_WEATHER_SUCCESS, SET_WEATHER_VIEW } from './types';
const weatherReducer = (state, action) => {
  switch (action.type) {
    case GET_WEATHER:
      return {
        ...state,
        loader: true,
      };

    case GET_WEATHER_SUCCESS:
      return {
        ...state,
        weather_cities: action.payload,
        loader: false,
      };

    case GET_WEATHER_ERROR:
      return {
        ...state,
        loader: false,
      };

    case SET_WEATHER_VIEW:
      return {
        ...state,
        weather_view: action.payload,
      };

    default:
      return state;
  }
};

export default weatherReducer;
