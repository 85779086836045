import React, { useReducer } from 'react';
import mapsReducer from './mapsReducer';
import MapsContext from './mapsContext';
import {
  GET_MAPS,
  GET_MAPS_ERROR,
  GET_MAPS_SUCCESS,
  SET_SATELLITE,
  FILTER_BY_CATEGORY,
  FILTER_BY_CATEGORY_SUCCESS,
  FILTER_BY_CATEGORY_ERROR,
  UNFILTER_BY_CATEGORY,
  UNFILTER_BY_CATEGORY_SUCCESS,
  UNFILTER_BY_CATEGORY_ERROR,
  GET_CATEGORY_TO_FILTER_MOBILE,
  GET_OUT_CATEGORY_TO_FILTER_MOBILE,
  FILTER_BY_CATEGORY_MOBILE,
  FILTER_BY_CATEGORY_MOBILE_SUCCESS,
  FILTER_BY_CATEGORY_MOBILE_ERROR,
  FILTER_BY_MARKER,
  FILTER_BY_MARKER_ERROR,
  FILTER_BY_MARKER_SUCCESS,
  UNFILTER_ALL,
  SHOW_ERROR,
  HIDE_ERROR,
} from './types';

const MapsState = (props) => {
  const initialState = {
    DATA: [],
    position: [-38.27794424639919, -69.0819821043512],
    bounds: null,
    map: null,
    getMap: true,
    markers: null,
    filtered: [],
    zoom: 8,
    categoriesToFilter: [],
    error: false,
    error_text: null,
    loader: false,
    map_related_content: false,
  };

  //REDUCER
  const [state, dispatch] = useReducer(mapsReducer, initialState);

  //GET MAPS
  const getMaps = async () => {
    dispatch({
      type: GET_MAPS,
    });
    try {
      const response = await fetch(process.env.REACT_APP_RN_MAPS).then((res) => res.json());
      dispatch({
        type: GET_MAPS_SUCCESS,
        payload: response.features,
      });
    } catch (error) {
      console.error();
      dispatch({
        type: GET_MAPS_ERROR,
      });
    }
  };

  //SET SATELLITE VIEW
  const setSatellite = async (state) => {
    dispatch({
      type: SET_SATELLITE,
      payload: !state,
    });
  };

  //FILTER CATEGORY
  const filterByCategory = async (data) => {
    dispatch({
      type: FILTER_BY_CATEGORY,
    });

    try {
      let categories = data.toString();
      const response = await fetch(`${process.env.REACT_APP_RN_MAPS}/?categories=${categories}`).then((res) =>
        res.json()
      );
      dispatch({
        type: FILTER_BY_CATEGORY_SUCCESS,
        payload: response.features,
      });
    } catch (error) {
      console.error();
      dispatch({
        type: FILTER_BY_CATEGORY_ERROR,
      });
    }
  };

  //FILTER CATEGORY MOBILE
  const filterByCategoryMobile = async (data) => {
    dispatch({
      type: FILTER_BY_CATEGORY_MOBILE,
    });
    try {
      let categories = data.toString();
      const response = await fetch(`${process.env.REACT_APP_RN_MAPS}/?categories=${categories}`).then((res) =>
        res.json()
      );
      dispatch({
        type: FILTER_BY_CATEGORY_MOBILE_SUCCESS,
        payload: response.features,
      });
    } catch (error) {
      console.error();
      dispatch({
        type: FILTER_BY_CATEGORY_MOBILE_ERROR,
      });
    }
  };

  //UNFILTER CATEGORY
  const unFilterByCategory = (data) => {
    dispatch({
      type: UNFILTER_BY_CATEGORY,
    });
    try {
      dispatch({
        type: UNFILTER_BY_CATEGORY_SUCCESS,
        payload: Number(data),
      });
    } catch (error) {
      console.error();
      dispatch({
        type: UNFILTER_BY_CATEGORY_ERROR,
      });
    }
  };

  //FIND CATEGORIE FOR CHECKING ITEM
  const findCategorie = (id) => {
    let categorie = state.filtered.filter((data) => data.properties.categories?.includes(String(id) || Number(id)));
    if (categorie.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  //FIND CATEGORIE BY ID
  const findCategorieById = (id) => {
    let categorie = state.categoriesToFilter.filter((data) => data === Number(id) || data === String(id));
    if (categorie.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  //GET MARKER BY NAME
  const getMarkerByName = async (data) => {
    const marker = encodeURIComponent(data);
    dispatch({
      type: FILTER_BY_MARKER,
    });
    try {
      const response = await fetch(`${process.env.REACT_APP_RN_MAPS}/?s=${marker}`).then((res) => res.json());
      if (response.features.length > 0) {
        dispatch({
          type: FILTER_BY_MARKER_SUCCESS,
          payload: response.features,
        });
      } else {
        showError('No hay elementos que coincidan con su búsqueda');
        await getMaps();
      }
    } catch (error) {
      console.error();
      dispatch({
        type: FILTER_BY_MARKER_ERROR,
      });
    }
  };

  //FILTER FOR CATEGORIE MOBILE
  const getCategoriesToFilter = (category) => {
    dispatch({
      type: GET_CATEGORY_TO_FILTER_MOBILE,
      payload: String(category),
    });
  };

  const getOutCategoriesToFilter = (category) => {
    dispatch({
      type: GET_OUT_CATEGORY_TO_FILTER_MOBILE,
      payload: String(category),
    });
  };

  const unfilterAll = async () => {
    dispatch({
      type: UNFILTER_ALL,
    });
    await getMaps();
  };

  //HANDLE ERRORS
  const showError = (text) => {
    dispatch({
      type: SHOW_ERROR,
      payload: text,
    });
    setTimeout(() => {
      dispatch({
        type: HIDE_ERROR,
      });
    }, 2000);
  };

  return (
    <MapsContext.Provider
      value={{
        DATA: state.DATA,
        map: state.map,
        markers: state.markers,
        position: state.position,
        bounds: state.bounds,
        satellite: state.satellite,
        filtered: state.filtered,
        categoriesToFilter: state.categoriesToFilter,
        getMap: state.getMap,
        zoom: state.zoom,
        error: state.error,
        error_text: state.error_text,
        loader: state.loader,
        setSatellite,
        getMaps,
        filterByCategory,
        unFilterByCategory,
        getMarkerByName,
        getCategoriesToFilter,
        getOutCategoriesToFilter,
        filterByCategoryMobile,
        findCategorie,
        findCategorieById,
        unfilterAll,
        showError,
      }}>
      {props.children}
    </MapsContext.Provider>
  );
};

export default MapsState;
