import React from "react";
import LayoutHome from "../../layouts/layout_home/LayoutHome";
import Home from "../../components/home/Home";

const HomePage = () => {
  setTimeout(() => {
    window.location.href = "/energia";
  }, 3000);
  return (
    <LayoutHome>
      <Home />
    </LayoutHome>
  );
};

export default HomePage;
